import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { json, Link } from 'react-router-dom'
import { subscribe } from "../utils/pubsub"
import CloudformationOutputs from '../CloudformationOutputs.json'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import { dateDiff } from '../utils/datediff'
import Button from '../formfields/Button'
import DeleteButton from '../formfields/DeleteButton'
import Card from '../ui-elements/Card'
import YellowAlert from '../ui-elements/YellowAlert'
import RedAlert from '../ui-elements/RedAlert'




type Props = {


}

function ListAssets({

}: Props) {

  const {
    tableData,
    setShowModal,
    sendMessageToWebsocket
  } = useContext(GlobalContext)



  const deleteItem = (Id: string) => {
    setShowModal({ "spinner": 'Deleting...' })
    const payload = JSON.stringify({
      action: "assets",
      subAction: "deleteAsset",
      Id: Id
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("assetDeleted", data => {
      if (data.Id === Id) {
        setShowModal(null)
      }
      unsubscribe()
    })

  }





  return <div className={`w-full flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-4 flex flex-col gap-4 items-center justify-between`}>

      <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
        <h3 className={`font-righteous text-3xl font-brandblue`}>Assets</h3>

        <div className={`flex flex-row gap-2 justify-center items-center`}>
          {tableData.AssetTypes && Object.keys(tableData.AssetTypes).length > 0 && <Button
            internalLinkUrl={`/asset`}
            text={`Add asset`}
            variant={`primary`}
            // size={`big`}
            fullwidth={false}
          />}
          <Button
            internalLinkUrl={`/asset-types`}
            text={`Manage types`}
            variant={`primary`}
            // size={`big`}
            fullwidth={false}
          />
        </div>
      </div>




      {(!tableData.AssetTypes || Object.keys(tableData.AssetTypes).length === 0) &&
        <YellowAlert fullwidth={true}><p>No asset types have been set up yet. <Link to={`/asset-types/`} className={`underline hover:opacity-80`}>Manage asset types.</Link></p></YellowAlert>}


      {tableData.AssetTypes && Object.keys(tableData.AssetTypes).length > 0 &&  (!tableData.Assets || Object.keys(tableData.Assets).length === 0) &&
        <p className={`w-full`}>No assets have been set up yet.</p>}


      {tableData && tableData.Assets && Object.keys(tableData.Assets).length > 0 && <div className={`w-full gap-2 flex md:flex-row flex-col flex-wrap`}>
        {Object.keys(tableData.Assets).map((AssetId: string, index: number) => {
          const Asset = tableData.Assets[AssetId]

          return <Card
            key={index}
            fullwidth={false}
          ><div
            className={`h-full md:w-48 w-full flex flex-col gap-4 justify-between`}>

              <div className={`flex flex-col gap-1`}>
                <p className={`font-bold`}>{Asset.AssetName}</p>
                <p className={`text-xs text-gray-600 italic`}>#{Asset.Id}</p>


                {Asset.AssetTypeId && Asset.AssetTypeId !== 'none' && tableData.AssetTypes && tableData.AssetTypes[Asset.AssetTypeId] &&
                  <p className={``}>Asset type:  {tableData.AssetTypes[Asset.AssetTypeId].DisplayName}</p>}</div>

              {Asset.AssetTypeId && Asset.AssetTypeId !== 'none' && tableData.AssetTypes && !tableData.AssetTypes[Asset.AssetTypeId] &&
                <RedAlert size='small' alignment='left'>
                  Missing asset type: #{Asset.AssetTypeId}
                </RedAlert>}



              <div className={`flex flex-row gap-2`}>


                <DeleteButton
                  deleteFunction={deleteItem}
                  functionParams={AssetId}
                  fullwidth={true}
                />


                <Button
                  internalLinkUrl={`/asset/${AssetId}`}
                  text={`Edit`}
                  variant={`primary`}
                  // size={`big`}
                  fullwidth={true}
                />

              </div>

            </div>
          </Card>

        })} </div>}

    </div>
  </div>
}
export default ListAssets