import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Text from '../formfields/Text'
import { validateForm } from '../utils/validateForm'
import { removeNonAlphanumeric } from '../utils/removeNonAlphanumeric'

type Props = {
}

function AssetTypeEdit({
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)

    const { Id } = useParams()
    const navigate = useNavigate()

    const thisAssetType = Id && tableData && tableData.AssetTypes && tableData.AssetTypes[Id]
    const initialFormData = {
        "DisplayName": { "value": '', "required": true, "type": "text" }
    }
    const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>(initialFormData)


    useEffect(() => {
        if (thisAssetType) {
            const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
            for (const key in formFields) {
                newFieldValues[key] = { ...newFieldValues[key], 'value': thisAssetType[key] }
            }

            setFormFields(newFieldValues)

        }
    }, [thisAssetType])

    const handleChange = (key: string, value: any) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        newFieldValues[key] = { ...newFieldValues[key], 'value': value }
        setFormFields(newFieldValues)
    }

    const saveItem = () => {
        setShowModal({ "spinner": 'Saving...' })
        let item_id = Id
        // generate a new Id if this is a new asset type
        if (!thisAssetType) {
            const item_name = formFields['DisplayName']['value'] || 'AS'
            item_id = `${removeNonAlphanumeric(item_name).substring(0,10)}-${Date.now()}`
        }
        const payload: ObjectStringKeyAnyValue = {
            action: "assets",
            subAction: "saveAssetType",
            formFields: formFields,
            Id: item_id
        }
        sendMessageToWebsocket(JSON.stringify(payload))
        const unsubscribe = subscribe("assetTypeSaved", data => {
            if (data.Id === item_id) {
                setShowModal(null)
                navigate(`/asset-types`)
            }
            unsubscribe()
        })
    }


    return <div className={`w-full flex flex-col items-center`}>
        <div className={`max-w-3xl w-full p-4 flex flex-col gap-4 items-center justify-between`}>

            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <h3 className={`font-righteous text-3xl font-brandblue`}>
                    {thisAssetType ?
                        `Edit Asset Type: ${thisAssetType.DisplayName}` :
                        `Add new asset type`}
                </h3>
            </div>


            <div className={`w-full flex flex-col items-center`}>
                <Text
                    handleChange={handleChange}
                    formFields={formFields}
                    labelText={`Display Name`}
                    fieldName={`DisplayName`}
                />
            </div>

            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <Button
                    internalLinkUrl={`/asset-types`}
                    text={`Back`}
                    variant={`gray`}
                />
                <Button
                    onClick={(e: any) => validateForm(e, formFields, setFormFields, saveItem)}
                    text={`Save`}
                />
            </div>

        </div>
    </div>

}
export default AssetTypeEdit