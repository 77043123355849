import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Spinner from '../ui-elements/Spinner'
import DeleteButton from '../formfields/DeleteButton'
import { isFunctionExpression } from 'typescript'
import Card from '../ui-elements/Card'
import YellowAlert from '../ui-elements/YellowAlert'
import AssociatedChecklists from '../ui-elements/AssociatedItems'
import { getThingsThatUseThisItem } from '../utils/getThingsThatUseThisItem'

type Props = {
}

function CustomDataFormats({
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)

    const { checklistId } = useParams()
    const navigate = useNavigate()


    const deleteRegex = (Id: string) => {
        setShowModal({ "spinner": 'Deleting...' })
        const payload = JSON.stringify({
            action: "checklists",
            subAction: "deleteRegex",
            Id: Id
        })
        sendMessageToWebsocket(payload)
        const unsubscribe = subscribe("regexDeleted", data => {
            if (data.Id === Id) {
                setShowModal(null)
            }
            unsubscribe()
        })

    }



    return <div className={`w-full flex flex-col items-center`}>
        <div className={`max-w-3xl w-full p-4 flex flex-col gap-4 items-center justify-between`}>

            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <h3 className={`font-righteous text-3xl font-brandblue`}>Custom Data Formats</h3>
                <Button
                    internalLinkUrl={`/custom-data-builder`}
                    text={`Add new`}
                    variant={`primary`}
                    // size={`big`}
                    fullwidth={false}
                />
            </div>



            {(!tableData.CustomDataFormats || Object.keys(tableData.CustomDataFormats).length === 0) && 
            <YellowAlert fullwidth={true}>No custom data formats have been added yet</YellowAlert>}



            {tableData && tableData.CustomDataFormats && Object.keys(tableData.CustomDataFormats).length > 0 && <div className={`w-full gap-2 flex md:flex-row flex-col flex-wrap`}>
                {Object.keys(tableData.CustomDataFormats).map((customDataFormatId: string, index: number) => {
                    const customDataFormat = tableData.CustomDataFormats[customDataFormatId]
                    const checklistsThatUseThisItem = getThingsThatUseThisItem('CustomDataFormatId', customDataFormatId, tableData)
                    return <Card
                        key={index}
                        fullwidth={false}
                    ><div
                    className={`h-full md:w-48 w-full flex flex-col gap-4 justify-between`}>

                            <div className={`flex flex-col gap-2`}>


                                <p className={`font-bold`}>{customDataFormat.RegexName}</p>

                                <AssociatedChecklists
                                    keyName={`CustomDataFormatId`}
                                    itemId={customDataFormatId}
                                />


                            </div>
                            <div className={`flex flex-row gap-2`}>


                                <DeleteButton
                                    deleteFunction={deleteRegex}
                                    functionParams={customDataFormatId}
                                    fullwidth={true}
                                />


                                <Button
                                    internalLinkUrl={`/custom-data-builder/${customDataFormatId}`}
                                    text={`Edit`}
                                    variant={`primary`}
                                    // size={`big`}
                                    fullwidth={true}
                                />

                            </div>

                        </div>
                    </Card>

                })} </div>}

        </div>
    </div>

}
export default CustomDataFormats