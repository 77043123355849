import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Text from '../formfields/Text'
import { validateForm } from '../utils/validateForm'
import { removeNonAlphanumeric } from '../utils/removeNonAlphanumeric'
import Dropdown from '../formfields/Dropdown'
import YellowAlert from '../ui-elements/YellowAlert'

type Props = {
}

function EditAsset({
}: Props) {

  const {
    tableData,
    sendMessageToWebsocket,
    setShowModal
  } = useContext(GlobalContext)

  const { Id } = useParams()
  const navigate = useNavigate()

  const thisAsset = Id && tableData && tableData.Assets && tableData.Assets[Id]
  const initialFormData = {
    "AssetName": { "value": '', "required": true, "type": "text" },
    "AssetTypeId": { "value": '', "required": true, "type": "text" }
  }
  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>(initialFormData)
  const [assetTypeOptions, setAssetTypeOptions] = useState<Array<ObjectStringKeyAnyValue>>([])


  useEffect(() => {
    // add asset types dropdown
    if (tableData && tableData.AssetTypes && Object.keys(tableData.AssetTypes).length > 0) {
      const assetTypes: ObjectStringKeyAnyValue[] = [{
        value: '',
        label: 'Select asset type'
      }]
      Object.keys(tableData.AssetTypes).forEach((key: string) => {
        assetTypes.push({
          value: key,
          label: tableData.AssetTypes[key].DisplayName
        })
      })
      setAssetTypeOptions(assetTypes)
    }

    // populate fields with existing data
    if (thisAsset) {
      const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
      for (const key in formFields) {
        newFieldValues[key] = { ...newFieldValues[key], 'value': thisAsset[key] }
      }
      setFormFields(newFieldValues)
    }

  }, [tableData])

  const handleChange = (key: string, value: any) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    newFieldValues[key] = { ...newFieldValues[key], 'value': value }
    setFormFields(newFieldValues)
  }



  const saveItem = () => {
    setShowModal({ "spinner": 'Saving...' })
    let item_id = Id
    // generate a new Id if this is a new asset type
    if (!thisAsset) {
      const item_name = formFields['AssetName']['value'] || 'AS'
      item_id = `${removeNonAlphanumeric(item_name).substring(0, 10)}-${Date.now()}`
    }
    const payload: ObjectStringKeyAnyValue = {
      action: "assets",
      subAction: "saveAsset",
      formFields: formFields,
      Id: item_id
    }
    sendMessageToWebsocket(JSON.stringify(payload))
    const unsubscribe = subscribe("assetSaved", data => {
      if (data.Id === item_id) {
        setShowModal(null)
        navigate(`/assets`)
      }
      unsubscribe()
    })
  }




  return <div className={`w-full flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-4 flex flex-col gap-4 items-center justify-between`}>

      <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
        <h3 className={`font-righteous text-3xl font-brandblue`}>
          {thisAsset ?
            `Edit Asset: ${thisAsset.AssetName}` :
            `Add new asset`}
        </h3>
      </div>



      {(!tableData.AssetTypes || Object.keys(tableData.AssetTypes).length === 0) &&
        <YellowAlert fullwidth={true}><p>No asset types have been set up yet. <Link to={`/asset-types/`} className={`underline hover:opacity-80`}>Manage asset types.</Link></p></YellowAlert>}


      {tableData.AssetTypes && Object.keys(tableData.AssetTypes).length > 0 &&
        <div className={`w-full flex flex-col items-center gap-4`}>
          <div className={`w-full flex flex-col items-center gap-4`}>
            <Text
              handleChange={handleChange}
              formFields={formFields}
              labelText={`Asset Name`}
              fieldName={`AssetName`}
            />

            <Dropdown
              handleChange={handleChange}
              formFields={formFields}
              labelText={`Asset Type`}
              fieldName={`AssetTypeId`}
              optionValues={assetTypeOptions}
            />
          </div>

          <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
            <Button
              internalLinkUrl={`/assets`}
              text={`Back`}
              variant={`gray`}
            />
            <Button
              onClick={(e: any) => validateForm(e, formFields, setFormFields, saveItem)}
              text={`Save`}
            />
          </div>
        </div>}

    </div>
  </div>

}
export default EditAsset