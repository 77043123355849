
import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import YellowAlert from './YellowAlert'
import { getThingsThatUseThisItem } from '../utils/getThingsThatUseThisItem'

type Props = {
    keyName: string,
    itemId: string
    itemType?: string
}

function AssociatedItems({
    keyName,
    itemId,
    itemType = 'checklist'
}: Props) {

    const {
        tableData
    } = useContext(GlobalContext)


    const [showAll, setShowAll] = useState(false)


    const itemsAssociatedWithThis = getThingsThatUseThisItem(keyName, itemId, tableData, itemType)



    return <div>


        {!showAll && itemsAssociatedWithThis.length > 0 &&
            <div 
            className={`hover:opacity-80 cursor-pointer`}
            onClick={() => setShowAll(true)}
            >
                <YellowAlert icon={true} size={`small`} alignment={`left`}>
                    Used by {itemsAssociatedWithThis.length} {itemType}{itemsAssociatedWithThis.length === 1 ? '' : 's'}
                </YellowAlert>
            </div>}



        {showAll && itemsAssociatedWithThis.length > 0 &&
            <div 
            className={`hover:opacity-80 cursor-pointer`}
            onClick={() => setShowAll(false)}
            >
                <YellowAlert icon={true} size={`small`} alignment={`left`}>
                    <div>
                        <p>Used by following {itemType}{itemsAssociatedWithThis.length === 1 ? '' : 's'}:</p>
                        <p>
                            {itemsAssociatedWithThis.map((item: any, index: number) => {
                                return <><Link
                                    className={`underline`}
                                    to={`/${itemType === 'checklist' ? 'checklists' : 'asset'}/${item.Id}`}>{item.DisplayName}</Link>{index + 1 < itemsAssociatedWithThis.length ? ', ' : ' '}</>
                            })}</p>
                    </div>
                </YellowAlert>
            </div>}

    </div>
}
export default AssociatedItems